@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/digital-numbers');


html, body {

  height: 100%;
  @apply bg-stone-400;
}

/* Score Board font */
@font-face {
  font-family: "Scoreboard";
  src: url("/assets/fonts/scoreboard.ttf") format("truetype");
  /* font-weight: 400; */
  font-style: normal;
}


body {
  margin: 0;
  /* Barlow Semi Condensed as default font */
  /* font-family: "Barlow", "Barlow Semi Condensed", sans-serif; */
  font-family: "Roboto Condensed", "Helvetica Neue", sans-serif;
}


.barlow-condensed {
  font-family: "Barlow Semi Condensed", sans-serif;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
}

.loader {
  border-top-color: #3498db;
  animation: spinner 0.9s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.blue-button {
  background-color: #007bff;
  /* background-color: #ff0000; */
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 12px 24px;
  margin-bottom: 16px;
  transition: background-color 0.2s;
}

.blue-button:hover {
  background-color: #0069d9;
  /* background-color: #d90000; */
}

.blue-button:active {
  background-color: #0062cc;
  /* background-color: #c20000; */
}

.blue-button:focus {
  outline: none;
}
.blue-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.green-button {
  background-color: #14ff00;
  border: none;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  font-size: 22px;
  padding: 12px 24px;
  margin-bottom: 16px;
  transition: background-color 0.2s;
}

.green-button:hover {
  background-color: #12e600;
}

.green-button:active {
  background-color: #10cc00;
}

.green-button:focus {
  outline: none;
}

.green-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.red-button {
  /* background-color: #007bff; */
  background-color: #ff0000;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 24px;
  margin-bottom: 16px;
  transition: background-color 0.2s;
}

.red-button:hover {
  /* background-color: #0069d9; */
  background-color: #d90000;
}

.red-button:active {
  /* background-color: #0062cc; */
  background-color: #c20000;
}

.red-button:focus {
  outline: none;
}

.team-transition {
  @apply transition-all duration-75 ease-in-out;
}


.question-button {
  @apply text-center text-black font-bold cursor-pointer bg-white font-roboto-condensed rounded-md;
  @apply min-w-[32vw] min-h-[6.45vh];
  @apply px-[5px] py-[12.5px];
  font-size: clamp(12px, calc(12px + (54vw - 175px) * 0.05), 54px);
  /* Need enough of a drop shadow to make the text readable */
  @apply text-shadow-lg;
}

